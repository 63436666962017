<template>
  <div class="app-container">
  </div>
</template>

<script>
import { getBackGroundUserList, createUser, updateUser } from "@/api/user";
import md5 from "js-md5";
import { encapsulationMenus, splitMenus, formatApiDate } from "@/utils/utils";

export default {
  computed: {

    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10
      },
      dialogStatus: "",
      rules: {
        amount: [
          { required: true, message: "购买价格不能为空", trigger: "change" }
        ],
      },
      form: {
        id: 0,
        name: "",
      },
      btnLoading: false,
      dialogFormVisible: false,
      editObj: {} // 修改的对象
    };
  },
  created() {
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };

      if (form.roles) {
        const resultArr = [];
        encapsulationMenus(form.roles, resultArr);
        params.roles = resultArr.length !== 0 ? resultArr.join("") : "";
      }
      getBackGroundUserList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      if (status) {
        this.dialogStatus =
          editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      } else {
        this.dialogStatus = "";
      }
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.account = editObj.username;
        this.form.name = editObj.nickname;
        this.form.isForbidden = editObj.is_forbidden;
        this.form.roles = splitMenus(editObj.roles);
      } else {
        this.form.id = "";
        this.form.account = "";
        this.form.name = "";
        this.form.isForbidden = "";
        this.$refs.form.resetFields();
      }
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const resultArr = [];
          encapsulationMenus(form.roles, resultArr);
          const params = {
            account: form.account,
            password: md5(form.password),
            name: form.name,
            roles: JSON.stringify(resultArr)
          };
          this.btnLoading = true;
          const dialogStatus = this.dialogStatus;
          if (dialogStatus === "CREATE") {
            createUser(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "保存成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          } else if (dialogStatus === "UPDATE") {
            params.id = form.id;
            params.isForbidden = form.isForbidden;
            updateUser(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "修改成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
        }
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
